div.add-bill-form {
    position:fixed;
    width: 62vw;
    height: 60px;
    padding: 25px;
    padding-top: 35px;
    margin-right: 8vw;
    margin-top: 54px;
    z-index: 2;
    background-color: white;

    display: flex;
    justify-content: space-between;

    border-radius: 4px;
    box-shadow: 2px 6px 20px 2px rgba(0, 0, 0, 0.15);
}


div.add-bill-form-item {
    padding-left: 20px;
    flex-grow: 1;
    flex-basis: 0px;
}

div.add-bill-form-big-item {
    flex-grow: 1.7;
    flex-basis: 0px;
}

div.add-bill-form-middle-item {
    flex-grow: 1.2;
    flex-basis: 0px;
}

div.add-bill-form-small-item {
    padding-left: 20px;
    flex-grow: 0.4;
    flex-basis: 0px;
}

div.add-bill-form-btn {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#cancel-bill-btn {
    cursor: pointer;
}