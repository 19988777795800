div.editBill-shares-menu {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    margin-bottom: 20px;
}


div.editBill-shares-menu-item-first {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 10px;
    padding-right: 20px;
    background: #FFFFFF;
    border: 0.5px solid #C5C5C5;
    border-radius: 6px 0px 0px 6px;
    cursor: pointer;
}

div.editBill-shares-menu-item-first:hover {
    background: #D7D7D7;
}

div.editBill-shares-menu-item-middle {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 10px;
    padding-right: 15px;
    background: #FFFFFF;
    border: 0.5px solid #C5C5C5;
    border-radius: 0px;
    cursor: pointer;
}

div.editBill-shares-menu-item-middle:hover {
    background: #D7D7D7;
}

div.editBill-shares-menu-item-last {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 10px;
    padding-right: 20px;
    background: #FFFFFF;
    border: 0.5px solid #C5C5C5;
    border-radius: 0px 6px 6px 0px;
    cursor: pointer;
}

div.editBill-shares-menu-item-last:hover {
    background: #D7D7D7;
}

div.shares-menu-item-current {
    background-color: rgb(215, 215, 215);
}
/* ----------------- */

div.editBill-shares-users {
    width: 44vw;
    padding-left: 17vw;

    margin-top: -30px;
    margin-bottom: 10px;

    display: flex;
}

div.editBill-shares-user {
    width: 5vw;
    padding-left: 40px;
}