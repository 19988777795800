header {
    position: fixed;
    padding-bottom: 10px;
    padding-left: 8vw;
    padding-right: 8vw;
    width: 84vw;
    top: 0;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


div.header-menu {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

div.header-menu-item {
    margin-right: 40px;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 20px;
    font-weight: normal;
    text-decoration: none;
    color: rgba(58, 58, 58, 1);
}

div.header-menu-item-current {
    margin-right: 40px;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;
    text-decoration: underline;
    color: rgba(11, 11, 11, 1);
}

div.header-right-menu {
    display: flex;
    justify-content: flex-end;
}

div.header-right-menu-add-bill-box {
    box-sizing: border-box;
    border: 2px solid #3A3A3A;
    border-radius: 8px;

    padding: 13px;
    padding-top: 4px;
    padding-bottom: 5px;

    margin-right: 5px;

    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;

    cursor: pointer;
}

div.header-right-menu-add-bill-box:hover {
    background-color: rgba(49, 139, 222, 1);
    border-color: rgba(49, 139, 222, 1);
    color: #FFFFFF;
}

div.header-right-menu-user-box {
    background: #3A3A3A;
    border-radius: 8px;

    width: 36px;
    text-align: center;
    padding-top: 6px;

    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;

    color: #FFFFFF;

    cursor: pointer;
}

div.under-header-box {
    display: flex;
    justify-content: flex-end;
}
