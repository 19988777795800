
div.billsListBox {
    width: 17vw;
    margin-left: -16px;
    padding-left: 16px;
}

div.billsList-head {
    height: 50px;
    padding-top: 10px;
    display: flex;
    align-items: center;
}

div.billsList-usersList {
    width: 180px;
    padding-left: 28px;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-left: -28px;
    background-color: rgba(248, 248, 248, 1);
}


div.billsList-usersList-item {
    padding-top: 3px;
    padding-bottom: 3px;

    cursor: pointer;
}

div.billsList-usersList-item:hover {
    background-color: rgba(231, 231, 231, 1);
    margin-left: -28px;
    padding-left: 28px;
}

div.billsList-usersList-item-current {
    padding-top: 3px;
    padding-bottom: 3px;
    background-color: rgba(231, 231, 231, 1);
    margin-left: -28px;
    padding-left: 28px;
    display: flex;
    align-items: center;

    cursor: pointer;
}

div.billsList {
    margin-top: 0px;
    margin-left: -16px;
    max-height: 70vh;
    overflow-y: auto;
}


.billsList-showMore {
    margin-top: 16px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.showMoreBtn {
    cursor: pointer;
}


div.checkMark {
    margin-left: -18px;
    margin-right: 11px;
}


i.arrow {
    border: solid rgba(58, 58, 58, 1);
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    margin-left: 10px;
    cursor: pointer;
}

i.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

i.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

i.up {
    margin-top: 10px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

i.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

div.checkMark {
    display: inline-block;
    transform: rotate(45deg);
    height: 10px;
    width: 5px;
    border-bottom: 2px solid rgba(58, 58, 58, 1);
    border-right: 2px solid rgba(58, 58, 58, 1);
}