
footer {
    padding-left: 120px;
    padding-top: 10px;
    position: absolute;
    bottom: 0;
    height: 40px;
    /* Footer height */
    font-size: 12px;
    color: rgba(58, 58, 58, 1);
}