div.editBill {
    width: 64vw;
    z-index: 1;
}

button {
    border: 2px solid rgba(113, 113, 113, 1);
    border-radius: 10px;
    height: 30px;

    background-color: white;

    font-size: 14px;
    font-weight: bold;
    color: rgba(113, 113, 113, 1);

    padding-left: 25px;
    padding-right: 25px;

    cursor: pointer;
}