div.editBill-payments-add, div.editBill-payment-row {
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


/* div.editBill-payments-add {
    margin-top: 5px;
    margin-bottom: 5px;
} */

/* div.editBill-payments-add-btns,  */
div.editBill-payment-btns {
    width: 20vw;

    display: flex;
    justify-content: flex-end;
    align-items: center;
}


/* div.editBill-payments-add-input, */
div.editBill-payment {
    width: 50vw;
    
    display: flex;
    justify-content: flex-start;
}

div.editBill-payment-error {
    width: 50vw;
    
    display: flex;
    justify-content: flex-start;

    background-color: #FFD6D5;
}

/* div.editBill-payments-add-input-name,  */
div.editBill-payment-name {
    width: 15vw;

}

/* div.editBill-payments-add-input-cost,  */
div.editBill-payment-cost {
    width: 7vw;

    display: flex;
    justify-content: flex-end;
    align-items:center;

}

/* div.editBill-payments-add-input-items,  */
div.editBill-payment-items {
    width: 5vw;

    display: flex;
    justify-content: flex-end;
    align-items:center;

}

/* div.editBill-payments-add-input-add-btn,  */
div.editBill-payment-edit-btn {
    width: 11vw;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
}

div.editBill-payments-btns {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    
    margin-top: -40px;
}

button.editBill-payment-cancel-btn {
    margin-left: 50px;
}

.editBill-payment-delete-btn {
    cursor: pointer;
}

/* ------------ edit row ------------ */

input.editBill-payment-name-input, #editBill-payments-add-input-name{
    width: auto;
    margin-right: 35px;
    margin-top: 14px;
    margin-bottom: 14px;
}

input.editBill-payment-cost-input, #editBill-payments-add-input-cost {
    margin-top: 14px;
    margin-bottom: 14px;

    margin-left: 20px;
    margin-right: 3px;

    text-align: right;
}

input.editBill-payment-items-input, #editBill-payments-add-input-items {
    /* width: auto; */
    margin-top: 14px;
    margin-bottom: 14px;

    margin-left: 25px;

    text-align: right;
}

h4.editBill-payment-items-input {
    margin-right: -45px;
    margin-left: 5px;
}
