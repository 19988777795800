
div.editBill-payments-add{
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-top: 5px;
    margin-bottom: 5px;
}

/* div.editBill-payments-add-btns {
    width: 20vw;

    display: flex;
    justify-content: flex-end;
    align-items: center;
} */

input.editBill-payments-add-input-name {
    width: auto;
    margin-right: 35px;
    margin-top: 14px;
    margin-bottom: 14px;
}

input.editBill-payments-add-input-cost {
    margin-top: 14px;
    margin-bottom: 14px;

    margin-left: 20px;
    margin-right: 3px;

    text-align: right;
}

input.editBill-payments-add-input-items {
    /* width: auto; */
    margin-top: 14px;
    margin-bottom: 14px;

    margin-left: 25px;

    text-align: right;
}


div.editBill-payments-add-input {
    width: 50vw;
    
    display: flex;
    justify-content: flex-start;
}

div.editBill-payments-add-input-name {
    width: 15vw;

}

div.editBill-payments-add-input-cost{
    width: 7vw;

    display: flex;
    justify-content: flex-end;
    align-items:center;

}

div.editBill-payments-add-input-items {
    width: 5vw;

    display: flex;
    justify-content: flex-end;
    align-items:center;

}

div.editBill-payments-add-input-add-btn {
    width: 15vw;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
}