#content-wrap {
    padding-top: 65px;
    padding-bottom: 2.5rem;
    /* Footer height */
    padding-left: 8vw;
}


a {
    color: inherit;
    /* blue colors for links too */
    text-decoration: inherit;
    /* no underline */
}


div.mainBox {
    display: flex;
    justify-content: flex-start;
}