div.account-popup-window {
    /* float: right; */
    position:fixed;
    width: 180px;
    height: 86px;
    margin-right: 8vw;
    margin-top: 54px;
    border-radius: 4px;
    box-shadow: 2px 6px 20px 2px rgba(0, 0, 0, 0.15);
    z-index: 2;
    background-color: white;
}


div.account-popup-window-textbox {
    padding-top: 10px;
    padding-left: 5px;
}

div.account-popup-window-signountbox {
    margin-top: 17px;
    padding-top: 3px;
    padding-left: 5px;
    height: 21px;
    border-top: 0.8px solid #E7E7E7;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    cursor:pointer;
}

div.account-popup-window-signountbox:hover {
    background-color: #F8F8F8;
}